import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import * as styles from '../styles/contact.module.css'
import { Helmet } from 'react-helmet'

export default function contact() {
  return (
      <Layout>
        <Helmet>
          <meta charSet='urtf-8'/>
          <title>Venture Games - Contact Us</title>
          <link rel="canonical" href="/jamesjohnstone" />
        </Helmet>
        
        <PageTitle title="Contact Us" bgImage="/ShooterBackgroundImage3.jpg"/>
        <div className={styles.contact}>
          <p>We would love you to contact us! To contact us you can either fill out this contact form <br/>or email us at <i>support@venturegames.net</i></p>
          <form method="post" action="/contactForm.php">
            <table className={styles.contactFormTable}>
              <tr>
                <td style={{width: "50%"}}>
                  <input name="name" type="text" className={styles.contactFormInput} placeholder="Name" required/>
                </td>
                <td style={{width: "50%"}}>
                  <input name="email" type="email" className={styles.contactFormInput} placeholder="Email" required/>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <input name="subject" type="text" className={styles.contactFormInput} placeholder="Subject" required/>
                </td>
              </tr>
              <tr>
                <td colspan="2" style={{height: "150px"}}>
                  <textarea name="message" className={styles.contactFormInput} placeholder="Message" required/>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <input type="submit" className={styles.contactFormSubmit} value="SUBMIT"/>
                </td>
              </tr>
            </table>
          </form>
        </div>
      </Layout>
  )
}
